/* ------------------- for chat-dashborad start ----------------------- */

.Chat-main-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.lable-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
}

.text-center-header {
  padding: 15px;
}
.text-center-margin {
  margin-left: 5px;
}

.sort-down-arrow {
  opacity: 0.2647758152173913;
  width: 20px;
  height: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Chat-main-header .row {
  margin: 0px;
}
.team-mamber-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 294px; */
  height: 191px;
  margin-top: 15px;
}
.response-speed-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 610px; */
  height: 194px;
  margin-top: 15px;
}
.chat-status-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 289px; */
  height: 194px;
  margin-top: 15px;
}

.chat-score-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 605px; */
  height: 243px;
}

.chat-live-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 608px; */
  height: 243px;
}

.total-chat-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 929px; */
  height: 243px;
}

.missed-chat-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 289px; */
  height: 243px;
}

.card-titel {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

.aside-cont-new-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 63px);
}
.aside-cont-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 35px);
}

.card-content-number {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  width: 50px;
  text-align: left;
  /* padding-left: 18px; */
}
.card-content-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  width: 78px;
  text-align: left;
  margin-top: 8px;
}

.response-speed-dropdown {
  background-color: #ecf2f4 !important;
  border-radius: 4px;
  width: 140px;
  height: 35px;
  border: none;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  background: url("../Images//table-arr.png") no-repeat right 8px center;
  background-size: 15px;
  cursor: pointer;
}

.select-text {
  color: #6e6e6e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 10px;
}

.response-card-content-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  width: auto;
  text-align: left;
  margin-top: 8px;
}

.aside-cont-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 108px);
}

.missed-chat-30 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  width: 117px;
  text-align: left;
}
.missed-chat-small-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  padding: 10px 9px 0;
}

.card-content-number .response-small {
  display: inline;
  font-weight: 200;
  font-size: 18px;
}

.response-row {
  margin: 35px 20px 0px 35px;
}

.team-member-center-text {
  text-align: center;
}
.team-member-30 {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  /* padding-left: 125px; */
  /* padding-top: 28px; */
}
.teamchat-p {
  font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}
.team-member-p {
  display: flex;
  padding: 10px 28px;
}

.float-search-chat {
  border: 1px solid #f0f0f0;
  width: 220px;
  height: 43px;
  top: 97px;
  z-index: 1;
  border-radius: 21.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 4px 17px 6px;
  cursor: pointer;
}

.back-to-main-dashboa {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  padding-top: 10px;
  padding-top: 15px;
  padding-left: 5px;
}
.removeleft-1 .row {
  margin-right: -10px;
  /* margin-right: -12px; */
}

.addmargin .row {
  margin-left: 10px;
}
/* dashboad css start by shlok */
.apexcharts-legend.position-bottom.center,
.apexcharts-legend.position-top.center {
  /* justify-content: center; */
  margin-bottom: 170px !important;
  margin-left: 304px !important;
}
.ticketToTask-chart-1 .apexcharts-legend.position-bottom.center {
  margin-bottom: 150px !important;
  margin-left: 200px !important;
}
.chat-lbl1 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl2 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl3 {
  text-align: initial;
  padding-left: 15px;
}
.chat-lbl4 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl5 {
  text-align: left;
  padding-left: 35px;
}
.chat-lbl6 {
  padding-left: 25px;
  text-align: left;
}
.chat-lbl7 {
  text-align: left;
  padding-left: 20px;
}
.dash-linechart #chart {
  margin-top: -5px !important;
}
/* dashboad css end */
/* ChatBot Create modal css start by shlok */
.mdlcancleImg {
  margin-top: -24px;
  float: right;
}
.mainDiv-crt {
  padding: 0px 15px 0px 15px;
}
.lbl-customerMdl {
  font-size: 15px;
  font-weight: 700;
}
.lbl-sourceChat {
  float: right;
  font-weight: 600;
}
.lbl-mdlHeader {
  margin-top: 10px;
  margin-bottom: 5px;
}
.mdl-lbl {
  font-weight: 500;
  color: #9b9b9b;
  margin-bottom: 4px;
}
.txt-border {
  border: 1px solid;
  color: #d6d4d4;
}
.chat-txt1 {
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  height: 38px;
  padding: 10px;
}
.chat-mdlMargin {
  margin-bottom: 15px;
}
#CreateCustomer-mdl {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 215px;
  display: inline-block;
  min-width: 870px !important;
  margin-top: -62px;
}
.drop-downlist-mdl {
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  height: 37px;
  padding: 10px;
  padding-left: 15px;
}
.dropdown-chat {
  color: #635e5e;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #f9f9f9 url("../Images/dropDown.png") no-repeat right 12px center;
  cursor: pointer;
}
.text-areaChatModel {
  background-color: #f9f9f9;
  padding: 12px;
  height: auto;
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #bdbdbd;
  resize: none;
}
.chatbutn-2 {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}
.chatAnchor {
  color: #2561a8;
  margin-top: 10px;
  margin-right: 15px;
}
.chatbtnDiv {
  float: right;
  margin-top: 15px;
}
.curshar-pointer {
  cursor: pointer;
}
#createTicketModal {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  display: inline-block;
  min-width: 610px !important;
  margin-top: 120px;
}
.openTciketLbl {
  font-size: 15px;
  font-weight: 600;
}
.flot-calcel {
  float: right;
}
.modal-lbl1 {
  font-size: 13px;
  font-weight: 400;
}
.span-lbl2 {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}
.modal-lbl2 {
  font-size: 13px;
  font-weight: 700;
}
.opn-ticketDiv {
  margin-top: 6px;
}
.no-mdl {
  font-weight: 700;
  color: #2561a8;
}
.padding-div {
  padding: 10px;
}
/* ChatBot Create modal css end */
/* dashboard Chatbot drawer css start kalpesh*/
.chatdrawer .ant-drawer-mask {
  background: none;
}
.chatdrawer .ant-drawer-content-wrapper {
  margin-top: 58px;
}
.chatdrawer .ant-drawer-wrapper-body {
  width: 325px;
}
.chatdrawer .chatleftdrawer {
  /* max-height: 200px;  */
  overflow: auto;
}
.ongoing {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}
.cancelbtn-chat {
  width: 17px;
  height: 16px;
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  cursor: pointer;
}
.chatpadding {
  padding: 0 10px;
  border-bottom: 1px solid #e8e8e8;
}
.chatdrawer .ant-drawer-body {
  background-color: #fffef7;
}
/* dashboard Chatbot drawer css end kalpesh */
/* Chatbot recent chat and drawer css start kalpesh*/
.table-bchat {
  background-color: #ebebeb;
  border-radius: 4px;
  width: 28px;
  height: 22px;
}
.chatreact .ReactTable {
  margin: 10px;
  border: none;
}
.chatreact .chatnamereact {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  display: initial;
  margin-left: 5px;
}
.chatreact .ReactTable .rt-tbody {
  background-color: #fff;
  border-radius: 8px !important;
}
.chatreact .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.actionleft {
  float: right;
  margin-top: -51px;
  margin-right: 20px;
}
.actionright {
  float: right;
  margin-top: -62px;
  margin-right: 20px;
}
.searchImg-raisechat {
  width: 20px;
  height: 20px;
  margin-left: -31px;
  margin-right: 8px;
}
.searchtextchat {
  border: none;
  background-color: #ffffff;
  border-radius: 4px;
  width: 260px;
  height: 43px;
  float: right;
  padding: 10px;
  margin-right: 4px;
}
.chatreact .chatthumb {
  width: 20px;
  height: 20px;
}
.drawerchat .ant-drawer-content-wrapper {
  height: 202px !important;
  margin-left: 347px;
  width: 72.5% !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
}
.drawerchat .ant-drawer-mask {
  background: none;
}
.drawerchat .crossdrawer {
  font-size: 28px;
  float: right;
  width: 25px;
  height: 25px;
  font-weight: 700;
  margin-top: -11px;
}
.drawerchat .chaton {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.drawerchat .mohitdrawer {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}
.drawerchat .num {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 15px;
}
.twothous {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}
.twotho {
  display: grid;
  padding-left: 25px;
}
.twothoustext {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}
/* Chatbot recent chat and drawer css end kalpesh*/
/* Chat Elements Css Start Kalpesh */
.chat-elementicon {
  border-radius: 100%;
  background-color: #fa6400;
  width: 30px;
  height: 30px;
  padding: 3px 7px;
}
.chatelementiconimg {
  width: 15px;
  height: 15px;
}
.ChatElementReact .ReactTable {
  border: none;
}
.ChatElementReact .ReactTable .rt-tbody .rt-td {
  color: #4a4a4a !important;
  padding: 10px 20px !important;
}
.chatelementbrowse {
  height: 25px;
  padding: 0;
  float: right;
  border: none;
  background-color: #9b9b9b;
  color: #fff;
}
.custom-file-label {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
}
.custom-file-label::after {
  position: absolute;
  top: 9px;
  right: 20px;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 25px;
  line-height: 12px;
  color: #495057;
  content: "Browse";
  background-color: #d3d9db;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
/* Chat Elements Css End */

/* Chat Theme page Css start By Shlok */
.chat-file-upl {
  font-weight: 400;
  color: #afafaf;
}
/* Chat Theme page Css end */
/* ChatConfigureBrand Css Start Kalpesh */
.setting-chat-config {
  padding: 20px 20px;
  /* background-color: #ecf2f4; */
  }
  .chat-config-center {
  margin: 0 auto;
  }
  .ChatThemeflex {
  display: flex;
  }
  .ChatConfigtheme {
  width: 70px;
  height: 70px;
  margin-right: 40px;
  }
  .ChatConfigthemelast {
  width: 70px;
  height: 70px;
  margin-right: 0px;
  }
  .ChatBataLogo {
  width: 100px;
  height: 50px;
  }
  .bataimgborder {
  border:1px solid #D9DADC;
  text-align: center;
  padding: 10px 0px;
  }
  .chatconfigpadding {
  padding: 10px 90px;
  }
  .chatconfigbtn {
  margin-top: 10px;
  }
  #pluspopup {
  width: 400px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  }
  .chat-config-center .table-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 480px;
}
  /* ChatConfigureBrand Css end Kalpesh */
  /* Chat Historical chat page css start by Shlok */
  .chat-historicalHdr{
    background-color: #ECF2F4;
    width: 100%;
    height: 57px;
  }
  .chatSrchHder{
    float: right;
    margin-top: 7px;
    margin-right: 15px;
  }
  .mychatHistoriLbl{
    color: #4A4A4A;
    font-family: Lato;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-top: 18px;
    margin-left: 25px;
  }
  .HistoChat .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0,0,0,0.05);
    border: none;
}
  /* Chat Historical chat page css End by Shlok */
  .chatconfigpadd {
    padding: 10px 60px;
  }
  .chatconfigpadd .txt-1 {
    background-color: #ecf2f4;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    border: none;
    padding: 9px;
  }
  .chatconfigpadding .txt-1 {
    background-color: #ecf2f4;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    border: none;
    padding: 9px;
}
/* -------------------------------------------------------------------- */
